export default {
  init() {
    // Fetch the form we want to deal with
    const forms = document.querySelectorAll("div[data-form-id]");

    forms.forEach((form) => {
      const formId = form.dataset.formId;

      if (!formId) return;

      const currentForm = form.querySelector("form");

      if (!currentForm) return;

      const formHandle = currentForm.querySelector("input[name=\"handle\"]");

      if (!formHandle) return;

      //Find the CSRF token hidden input, so we can replace it
      let csrfInput = currentForm.querySelector(
        "input[name=\"CRAFT_CSRF_TOKEN\"]"
      );

      if (!csrfInput) return;

      // Fetch the new token for the form and replace the CSRF input with our new one
      fetch("/actions/formie/forms/refresh-tokens?form=" + formHandle.value)
        .then((result) => {
          return result.json();
        })
        .then((result) => {
          csrfInput = result.csrf.input;

          if (!form) return;

          const formInputField = form.querySelector(
            "input[name=\"" + result.csrf.param + "\"]"
          );

          if (!formInputField) return;

          formInputField.value = result.csrf.token;
        });
    });
  },
};
